// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-changelog-tsx": () => import("./../../../src/pages/changelog.tsx" /* webpackChunkName: "component---src-pages-changelog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-feed-tsx": () => import("./../../../src/pages/feed.tsx" /* webpackChunkName: "component---src-pages-feed-tsx" */),
  "component---src-pages-flows-tsx": () => import("./../../../src/pages/flows.tsx" /* webpackChunkName: "component---src-pages-flows-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-library-tsx": () => import("./../../../src/pages/library.tsx" /* webpackChunkName: "component---src-pages-library-tsx" */),
  "component---src-pages-login-facebook-tsx": () => import("./../../../src/pages/login/facebook.tsx" /* webpackChunkName: "component---src-pages-login-facebook-tsx" */),
  "component---src-pages-login-github-tsx": () => import("./../../../src/pages/login/github.tsx" /* webpackChunkName: "component---src-pages-login-github-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-notifications-manage-tsx": () => import("./../../../src/pages/notifications/manage.tsx" /* webpackChunkName: "component---src-pages-notifications-manage-tsx" */),
  "component---src-pages-notifications-unsubscribe-tsx": () => import("./../../../src/pages/notifications/unsubscribe.tsx" /* webpackChunkName: "component---src-pages-notifications-unsubscribe-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-card-tsx": () => import("./../../../src/templates/card.tsx" /* webpackChunkName: "component---src-templates-card-tsx" */),
  "component---src-templates-contributor-tsx": () => import("./../../../src/templates/contributor.tsx" /* webpackChunkName: "component---src-templates-contributor-tsx" */),
  "component---src-templates-doc-tsx": () => import("./../../../src/templates/doc.tsx" /* webpackChunkName: "component---src-templates-doc-tsx" */),
  "component---src-templates-newsletter-tsx": () => import("./../../../src/templates/newsletter.tsx" /* webpackChunkName: "component---src-templates-newsletter-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

