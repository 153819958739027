import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { pathTo } from '../../routes';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Library`}</h1>
    <p>{`Uniflow Library list all `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/search?q=keywords:uniflow"
      }}>{`NPM`}</a>{` public packages view as Card.`}</p>
    <p>{`Each Card that can combine multiple Catalogs such as Flows and Clients.`}</p>
    <p>{`Use the search bar to the left to find a Card.`}</p>
    <p>{`Flows and Clients are `}<Link to={pathTo('doc', {
        'slug': 'concepts'
      })} mdxType="Link">{`key`}</Link>{` part of Uniflow.`}</p>
    <p>{`To make you own Flow or Client public, you can get more information at `}<Link to={pathTo('doc', {
        'slug': 'submitting-to-library'
      })} mdxType="Link">{`Submitting to Library`}</Link></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      